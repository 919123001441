<!--
 * @Author: zhongxq
 * @Date: 2023-10-20 22:15:51
 * @LastEditors: zhongxq
 * @LastEditTime: 2023-10-29 02:11:31
 * @FilePath: \beijing-peoples-art-museum\src\views\mobile\culture\guide\index.vue
 * @Description: 观影指南
 *
-->
<template>
  <div
    class="fixed-page-mobile culture-guide-index-mobile"
    v-infinite-scroll="handleMore"
    :infinite-scroll-distance="100"
  >
    <div class="container">
      <!-- <div class="title">
        <c-image :src="require('@/assets/img/performance/title-icon.png')" />
        <span>观剧指南</span>
      </div> -->
      <div class="map-box">
        <Map :list="list" />
        <div class="tab-list">
          <span
            v-for="item in tabList"
            :key="item.value"
            :class="['tab', { active: item.value === activeTab }]"
            @click="handleTab(item)"
            >{{ item.label }}</span
          >
        </div>
      </div>
      <div v-if="activeTab !== 'desc'" class="list">
        <div v-for="item in list" :key="item.id" class="list-item">
          <div class="top">
            <c-image :src="item.image" />
            <div class="right">
              <p class="conten-t1">{{ item.name }}</p>
              <p class="conten-t2">{{ item.descript }}</p>
            </div>
          </div>
          <div class="content">
            <p class="conten-t3">
              电话：<span class="color-primary">{{ item.phone }}</span>
            </p>
            <p class="t4">地址：{{ item.address }}</p>
          </div>
        </div>
      </div>
      <div v-else class="notice">
        <!-- <div class="custom-title">
          <span>购票须知</span>
        </div> -->
        <div class="html-content" v-html="desc"></div>
      </div>
    </div>
    <PageFooter />
  </div>
</template>

<script>
import { getServicePage, getArticlePage } from "@/service/culture";
import Map from "./components/Map.vue";
export default {
  components: { Map },
  data() {
    return {
      activeTab: this.$route.query.tab || "",
      // tabList: [
      //   { value: "FOOD", label: "周边美食" },
      //   { value: "HOTEL", label: "周边酒店" },
      //   { value: "SCENERY", label: "周边景点" },
      //   { value: "SHOPPING", label: "周边购物" },
      //   { value: "desc", label: "购票须知" },
      // ],
      list: [],
      pager: {
        pageNo: 1,
        pageSize: 20,
      },
      desc: "",
    };
  },
  created() {
    this.$store.dispatch("getDict", ["zhoubian"]);
    this.getDesc();
  },
  computed: {
    tabList() {
      let list = this.$store.state.dictMap["zhoubian"] || [];
      list = list.map((item) => {
        return { value: item.id, label: item.value };
      });
      list.push({ value: "desc", label: "购票须知" });
      return list;
    },
  },
  watch: {
    tabList() {
      if (!this.activeTab && this.tabList.length > 0) {
        this.activeTab = this.tabList[0].value;
      }
    },
    activeTab() {
      this.activeTab !== "desc" && this.handleSearch();
    },
  },
  methods: {
    // 获取购票须知
    async getDesc() {
      const res = await getArticlePage({
        pageNo: 1,
        pageSize: 1,
        condition: {
          level1: "culture",
          level2: "GPXZ",
          status: 1,
        },
      });
      const data = res.data?.data || [];
      if (data.length) {
        this.desc = data[0].body;
      }
    },
    handleTab(item) {
      this.activeTab = item.value;
      this.activeTab !== "desc" && this.handleSearch();
    },
    // 获取周边
    async getData() {
      try {
        const res = await getServicePage({
          ...this.pager,
          condition: { serviceId: this.activeTab },
        });
        if (res.data) {
          const data = res.data.data || [];
          if (data.length < this.pager.pageSize) {
            this.hasNextPage = false;
          }
          if (this.pager.pageNo === 1) {
            this.list = data;
          } else {
            this.list = this.list.concat(data);
          }
        }
      } catch (e) {
        console.log(e);
        if (this.pager.pageNo === 1) {
          this.list = [];
        }
      }
    },
    handleSearch() {
      this.hasNextPage = true;
      this.pager.pageNo = 1;
      this.getData();
    },
    handleMore() {
      console.log('mo');
      if (this.activeTab === "desc") return;
      clearTimeout(this.timer);
      if (!this.hasNextPage) return;
      this.timer = setTimeout(() => {
        this.pager.pageNo++;
        this.getData();
      }, 1000);
    },
  },
};
</script>

<style lang="less">
@import url("~@/assets/style/mixin.less");

.culture-guide-index-mobile {
  p {
    padding: 0;
    margin: 0;
  }
  .container {
    background-color: #ffffff;
    margin: 0 auto 50/75rem;
    width: 100%;
    box-sizing: border-box;
    .title {
      font-size: 26/75rem;
      font-weight: 500;
      color: #212122;
      padding-left: 26/75rem;
      position: relative;
      .img {
        position: absolute;
        left: 0/75rem;
        top: 50%;
        transform: translateY(-50%);
        width: 14/75rem;
        height: 26/75rem;
      }
    }
    .map-box {
      width: 100%;
      height: 420/75rem;
      margin: 30/75rem 0;
      background-color: #ffffff;
      position: relative;
    }
    .tab-list {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 50/75rem;
      background: rgba(248, 211, 159, 0.8);
      display: flex;
      align-items: center;
      .tab {
        flex: 1;
        height: 50/75rem;
        line-height: 50/75rem;
        font-size: 22/75rem;
        text-align: center;
        cursor: pointer;
        &:hover,
        &.active {
          background: #f9680d;
          color: #ffffff;
        }
      }
    }
    .list {
      padding: 0 30/75rem;
      box-sizing: border-box;
      width: 100%;
      .list-item {
        width: 100%;
        & + .list-item {
          padding-top: 20/75rem;
          margin-top: 20/74rem;
          border-top: 1/75rem solid #d8dade;
        }
        .top {
          display: flex;
          .img {
            width: 250/75rem;
            height: 187/75rem;
            flex-shrink: 0;
          }
          .right {
            padding: 10/75rem 0 10/75rem 20/75rem;
            p {
              color: #212122;
              .text-overflow(1);
            }
            .conten-t1 {
              font-size: 34/75rem;
              line-height: 38/75rem;
              font-weight: 800;
            }
            .conten-t2 {
              margin-top: 10/75rem;
              font-size: 28/75rem;
              .text-overflow(3);
            }
          }
        }
        .content {
          box-sizing: border-box;
          margin-top: 20/75rem;
          p {
            color: #212122;
            .text-overflow(1);
          }
          .conten-t3 {
            font-size: 30/75rem;
          }
          .t4 {
            font-size: 26/75rem;
            color: #909399;
          }
        }
      }
    }
    .notice {
      .custom-title {
        text-align: left;
        padding-left: 13/75rem;
        font-size: 18/75rem;
        font-weight: 800;
        color: #212122;
        line-height: 18/75rem;
        position: relative;
        &::before {
          content: "";
          display: inline-block;
          width: 3/75rem;
          height: 16/75rem;
          background: #21424b;
          position: absolute;
          left: 0;
          top: 2/75rem;
        }
      }
      .html-content {
        margin: 30/75rem 0;
        line-height: 34/75rem;
        white-space: pre-wrap;
      }
    }
  }
}
</style>
